import boreholeImg from "../assets/images/borehole2.jpg"
import boreholeImg1 from "../assets/images/borehole2.jpg"
import boreholeImg2 from "../assets/images/borehole2.jpg"

import sprinkle1 from "../assets/KenyanImages/irrigation_borehole_img.jpg"
import sprinkle2 from "../assets/KenyanImages/irrigation_borehole_img.jpg"
import sprinkle0 from "../assets/images/irrigation1.jpg"

import oilwell1 from "../assets/images/oilwell1.avif"
import oilwell2 from "../assets/images/oilwell2.jpg"

import sub1 from "../assets/images/sub1.avif"
import sub2 from "../assets/images/sub2.avif"

import solar1 from "../assets/images/solar1.avif"
import solar2 from "../assets/images/solar2.avif"

import tower1 from "../assets/images/tower1.jpg"
import tower2 from "../assets/images/tower2.jpg"


export const Services = [
    {
      icon: "LiaIndustrySolid",
      name: "Industrial Borehole",
      description: "We provide specialized borehole drilling services for industries, ensuring a reliable and sustainable water supply. Our team uses advanced drilling technology to meet the high-volume water demands of factories, production facilities, and other industrial operations. From initial site surveys to borehole installation, we ensure precision, compliance with environmental standards, and long-lasting performance tailored to industrial requirements.",
      desc1: "Industrial borehole drilling is a specialized process that involves creating deep, reliable water sources to meet the high demands of industrial facilities such as factories, manufacturing plants, and mining operations. The process begins with a thorough site survey and hydrogeological assessment. Experts use advanced geophysical equipment to study subsurface formations and identify the most promising location for drilling, ensuring optimal water yield. This step includes analyzing the aquifer's depth, water quality, and geological conditions, as well as securing necessary permits from local authorities to comply with environmental and regulatory standards. Once the site is confirmed, a high-powered rotary drilling rig is mobilized to the location. This equipment is equipped with robust drill bits capable of penetrating hard rock formations and creating boreholes that often extend hundreds of meters into the earth.",
      desc2: "During the drilling phase, a casing is installed to prevent the borehole walls from collapsing and to protect the water source from contamination. The casing is typically made of steel or PVC and is sealed with grout to ensure durability and longevity. Following this, the borehole is equipped with screens and filters to allow water to flow while blocking sediments. A pump system, often a submersible or turbine pump, is installed to extract the water efficiently. The pump is selected based on the depth of the borehole and the volume of water required. Once operational, the borehole is tested for yield and water quality to verify it meets industrial standards. The entire process requires technical expertise, precision engineering, and adherence to strict safety protocols. Proper maintenance of the borehole, including regular inspections and pump servicing, ensures a sustainable and long-lasting water supply for industrial needs. This detailed approach makes industrial boreholes a vital investment for industries seeking a reliable and sustainable solution for their water demands.",
      route: "industrial-borehole",
      img: boreholeImg,
      span:"Done fast, done right",
      img1:boreholeImg1,
      img2:boreholeImg2
    },
    {
      icon: "WiDaySprinkle",
      name: "Irrigation Borehole",
      description: "Our irrigation borehole services are designed to cater to agricultural needs, ensuring a consistent and adequate water supply for farming activities. We implement advanced methods to drill and equip boreholes that support irrigation systems, improving crop yields and enabling efficient water management for small-scale farmers and large agribusinesses alike.",
      desc1:"Building an irrigation borehole is a meticulous process requiring technical expertise, advanced equipment, and careful planning to ensure a sustainable water source for agricultural needs. It begins with a hydrogeological survey to identify a suitable site and aquifer characteristics, including depth, size, and water quality. Once identified, the borehole is drilled using rotary or percussion rigs, and steel or PVC casing is installed to stabilize the structure and prevent contamination. A screen is placed at the aquifer zone to filter debris, followed by gravel packing to improve filtration. The borehole is then developed by flushing out fine particles to enhance water flow, and a submersible pump or hand pump is installed to deliver water to the irrigation network, which may include sprinklers or drip systems tailored to the specific farming requirements.",
      desc2:"Technical knowledge is essential throughout the process, from site selection to pump installation and water quality testing. Choosing the right pump depends on borehole depth, while water quality analysis ensures the suitability of water for crops and soil. Sustainable design considers aquifer recharge rates and seasonal fluctuations to avoid over-extraction. Advanced systems, such as solar-powered pumps and automated irrigation management, enhance efficiency and reduce costs. Regular maintenance, including cleaning and servicing pumps, ensures the borehole remains functional over time, making it a reliable investment for agricultural productivity.",
      route: "irrigation-borehole",
      span:"Meeting your agricultural needs",
      img:sprinkle0,
      img1:sprinkle1,
      img2:sprinkle2
    },
    {
      icon: "FaOilWell",
      name: "Well Hand Pumps",
      description: "We supply and install durable, efficient well hand pumps, ideal for areas with limited access to electricity or mechanical alternatives. Our pumps are designed to provide an easy, cost-effective solution for accessing clean water from shallow wells, ensuring reliable and long-term functionality even in remote or underserved communities.",
      route: "oil-well-drilling",
      span:"Rural area? we'll take care of you too",
      desc2:"The pump head, which houses the handle and spout, is constructed to endure frequent use and exposure to the elements. The handle is connected to a system of levers that operate the piston within the cylinder, pulling water into the rising main with each stroke. Proper installation of the pump ensures a sealed system, critical for maintaining suction and preventing water contamination. Additionally, gravel packing or sanitary seals may be used around the casing to filter impurities and protect the well from surface contamination. Maintenance considerations are integral to the design, with easy-to-disassemble components allowing for periodic checks and repairs. This ensures long-term functionality, especially in remote or underserved areas where access to replacement parts and technical support may be limited. For users, well hand pumps represent a cost-effective, sustainable solution for water extraction, requiring no electricity or fuel and capable of providing clean, reliable water with minimal maintenance. Whether for agricultural, household, or community use, they are built with resilience and user accessibility in mind, making them an indispensable tool in water-scarce regions.",
      desc1:"Well hand pumps are mechanical devices designed to draw water manually from shallow or medium-depth boreholes or wells. Their construction involves a meticulous process, blending engineering precision with the principles of hydraulics to create a durable, user-friendly water extraction solution. The process begins with selecting the appropriate site for drilling, often determined by geological surveys to ensure the well intersects with a reliable water table. Once the borehole is drilled to the desired depth—typically no deeper than 50 meters for manual pumps—a casing is installed to stabilize the borehole walls and prevent contamination. At this point, the well's diameter is carefully measured to accommodate the pump cylinder, which houses a piston system. This piston system, commonly made of corrosion-resistant materials like stainless steel or brass, creates the suction needed to draw water upward when operated by the user. A rising main pipe, typically PVC or galvanized steel, is installed to guide water from the cylinder to the pump head.",
      img:oilwell1,
      img1:oilwell2,
      img2:oilwell1

    },
    {
      icon: "FaArrowUpFromWaterPump",
      name: "Submersible Pumps",
      description: "We offer high-performance submersible pumps for extracting water from deep boreholes and wells. Suitable for industrial, agricultural, and residential purposes, our pumps are designed for efficiency and durability. They are capable of operating under water with minimal maintenance, making them a reliable solution for high-capacity water delivery needs.",
      route: "water-pump-installation",
      desc2:"The installation of submersible pumps into boreholes involves technical expertise and precision. First, the borehole is drilled to the required depth using advanced rotary or percussion drilling equipment, ensuring that the aquifer is reached. The borehole is then cased to prevent collapse and contamination. After the borehole is ready, the submersible pump is carefully lowered into it using a suspension cable and delivery pipe. Electrical wiring is run alongside the pipe to supply power to the pump. Once installed, the pump is tested to ensure optimal functionality, including checks for flow rate, pressure, and energy consumption. Engineers also account for various factors such as the depth of the water table, borehole diameter, and intended water usage to select the appropriate pump size and power. For borehole drilling, it is crucial to partner with professionals who understand the geological conditions of the area and can tailor the pump system to ensure reliable and long-lasting performance.",
      desc1:"Submersible pumps are specialized devices designed to operate while completely submerged in water, making them ideal for extracting water from boreholes, wells, or deep underground aquifers. These pumps are engineered with precision to handle the high pressures and demanding environments encountered in such applications. The construction process starts with a robust motor housed in a hermetically sealed casing to prevent water ingress. This motor, typically powered by electricity, is coupled with a pump mechanism through a shaft, ensuring a direct transfer of energy. The pump mechanism comprises multiple impellers arranged in stages, which work sequentially to lift water to the surface. These impellers are strategically enclosed within a diffuser casing, which optimizes the flow and reduces turbulence, thereby enhancing efficiency. The materials used for constructing submersible pumps are corrosion-resistant, such as stainless steel or high-grade cast iron, to withstand prolonged exposure to water and minerals.",
      span:"Water is Life",
      img:sub1,
      img1:sub2,
      img2:sub1
    },
    {
      icon: "MdSolarPower",
      name: "Solar Installation",
      description: "Our solar installation services focus on renewable energy solutions tailored for powering water systems, homes, and businesses. We design and install efficient solar systems that provide sustainable energy, reduce operational costs, and promote environmental conservation. Whether for borehole systems or general electricity needs, our solar setups are customized to meet specific client demands.",
      route: "solar-power-systems",
      span:"Renewable energy. The future is here",
      desc2:"Technical expertise is crucial in the integration of solar panels with the borehole's pumping system. A solar charge controller regulates the power flow, preventing overcharging of connected batteries (if a storage system is used) and protecting the equipment. Direct solar-to-pump systems bypass batteries and rely on an inverter to convert DC power from the panels to AC power required by the pump. Smart inverters with MPPT (Maximum Power Point Tracking) technology optimize the energy conversion, ensuring efficient operation even under fluctuating sunlight conditions. The borehole pump, typically a submersible or surface pump, is carefully matched to the solar system's output to ensure seamless operation. Proper electrical wiring, grounding, and the use of weatherproof junction boxes are essential for safety and longevity. Additionally, remote monitoring systems can be integrated to provide real-time performance data and alerts, enabling proactive maintenance. This holistic approach to solar installation not only reduces operational costs but also ensures reliable water supply, making it an ideal solution for agricultural, industrial, or residential borehole applications.",
      desc1:"Solar installations for powering borehole systems are designed to harness renewable energy for sustainable water extraction. The process begins with a detailed site assessment to evaluate solar irradiance, geographic conditions, and water demand. Engineers calculate the borehole's power requirements based on the pump specifications, depth of the water table, and daily water output needs. Solar panels are selected and sized to match these energy requirements, ensuring optimal performance throughout the year. The selected panels are typically mounted on robust, corrosion-resistant structures designed to withstand local weather conditions, such as strong winds or heavy rains. Proper alignment and tilt angles are calculated to maximize sunlight capture, and panels are connected in series or parallel configurations based on the voltage and current requirements of the borehole system. During the installation, care is taken to position the panels in an area free from shade or obstructions, as shading significantly reduces efficiency.",
      img:solar1,
      img1:solar2,
      img2:solar1
    },
    {
      icon: "GiStoneTower",
      name: "Tower Construction",
      description: "We specialize in constructing sturdy and efficient towers for elevated water tanks and other critical infrastructure. Our tower construction services include engineering, design, and installation to ensure safety, durability, and adherence to industry standards. These structures provide effective water storage and distribution solutions for residential, industrial, and agricultural purposes.",
      route: "stone-tower-construction",
      span:"Sturdy and Efficent towers",
      desc1:"Tower construction for borehole drilling involves creating durable and efficient structures designed to support water storage tanks or other essential equipment required for water management. The process begins with a thorough site assessment and soil testing to determine the load-bearing capacity of the ground. Engineers evaluate the site’s terrain and environmental conditions to ensure the stability of the tower, especially in areas prone to seismic activity or high wind loads. After this, the tower's design is developed based on the volume of water to be stored and the height required to generate adequate water pressure. Structural engineers draft blueprints incorporating safety standards, material specifications, and load calculations to ensure durability and longevity. Materials such as reinforced concrete, galvanized steel, or a combination of both are commonly used, as they offer high strength and resistance to environmental wear.",
      desc2:"The construction process typically involves several stages. First, a solid foundation is laid, often involving reinforced concrete footings that anchor the structure firmly into the ground. This is critical for ensuring stability under the heavy load of a filled water tank. Next, the tower's frame is erected using prefabricated steel or concrete components. These components are carefully aligned and secured using high-grade bolts or welding techniques to maintain structural integrity. Afterward, the water tank is mounted atop the tower, usually made from materials such as polyethylene, stainless steel, or fiberglass, depending on capacity and usage requirements. During this phase, workers also install access ladders, railings, and platforms to facilitate maintenance. Piping systems and valves are integrated to connect the tank with the borehole's pump and the water distribution network. Testing and inspection are performed at every stage to ensure compliance with engineering standards, and the final structure is painted or coated to protect against corrosion. The result is a reliable and safe tower capable of sustaining continuous water storage and supply for residential, agricultural, or industrial applications.",
      img:tower1,
      img1:tower2,
      img2:tower1
    },
  ];

  export const navItems = [
    { label: "About", href: "#about" }, 
    { label: "Services", href: "#services" },
    { label: "Get A Free Quote", href: "https://wa.me/+254759522983?text=Hello,%20I%20would%20like%20to%20place%20an%20order" }, 
    { label: "Projects", href: "/projects" }, 
  ];
  
