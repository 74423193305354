import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'


const FloatingWhatsUp = ({className}) => {
  return (
    <div className={`${className}`} >
      <FloatingWhatsApp
      phoneNumber='+254729001057'
      accountName='Hatua Kenya'
      statusMessage='Borehole drilling co. '
      chatMessage='Hey There, when do you want to kick start your project?'
      avatar='/assets/images/logo/borehole2.jpg'
      darkMode={false}
      allowClickAway={false}
      />
    </div>
  )
}

export default FloatingWhatsUp
