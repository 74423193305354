import React from 'react'
import { useParams} from "react-router-dom"
import { Services } from '../../constants'
import { IoIosHome } from "react-icons/io";
import { useLocation } from 'react-router-dom';

const ServiceDetails = () => {
    const {serviceName} = useParams() //Get the service Id from the URL
    const {state} = useLocation(); //Access the state passed from navigate()

    //Fallback in case state is not available
    const service = Services.find((s)=> s.route === serviceName) || state?.service

  

    if(!service)
      return <div>Service is not found</div>
  return (
    <div className="bg-black text-white px-5">
      <div className='w-full h-[200px] bg-white overflow-hidden relative'>
        <img
         alt={service.name}
         className='h-full w-full object-cover transition-transform duration-500 hover:scale-110'
         src={service.img}
         />
         <div className="absolute inset-0 bg-black bg-opacity-70 group-hover:bg-opacity-60"></div>
         <div className='absolute inset-0 flex flex-col justify-center mx-10 text-start'> 
                 <div className='font-black  md:text-2xl text-xl mb-2 text-white'>{service.name}</div>
                 <div>{service.span}</div>
             </div>
      </div>

                  
                         
                 
                     
      <div className='flex space-x-1 py-5 items-center justify-start'>
      <IoIosHome/> 
      <span className='block mr-2 tracking-wider font-light'>-{service.name}</span>
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 gap-x-2 gap-y-1'>
        <img alt={service.name}
              className='h-[400px] w-full bg-white object-cover'
              src={service.img1}/>
        <div className='text-sm md:text-lg tracking-tight px-2 py-2 flex items-start'>{service.desc1}</div>

        <div className='text-sm md:text-lg tracking-tight  px-2 py-2 flex items-start'>{service.desc2}</div>
        <img alt={service.name}
              className='h-[400px] w-full object-cover object-center'
              src={service.img2}/>
      
      </div>
  </div>
  )
}

export default ServiceDetails

     
    
